@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

body {
    background-color: #f5f5f5;
    font-family: "Ubuntu", sans-serif !important;
}

.widget-title {
    color: #3cb371;
    font-size: 22px;
    padding-bottom: 0.5rem;
    margin-bottom: 15px;
    border-bottom: 1px solid #eeeeee;
}

.widget-area-2 {
    color: #3e5569 !important;
    background: #fff;
    margin-top: 1rem;
    padding: 20px;
    border-radius: 2px;

    table {
        color: #3e5569 !important;
    }
}
